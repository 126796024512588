module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/boat-logo-512.jpg","name":"Разходка с лодка в Неа Перамос - Boattrip","short_name":"Boattrip","start_url":"/","background_color":"#F2F2F2","theme_color":"#0A75C1","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b3e18a18e42a754560cc217888e67d82"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["bg","en"],"defaultLanguage":"bg","redirect":false,"siteUrl":"https://boat-neaperamos.com/","trailingSlash":"never","i18nextOptions":{"fallbackLng":"bg","defaultNS":["common"],"debug":false,"lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
