import app from "../utils/firebase";

export default class AuthUserService {
  static unsubscribe;

  static async loadAuthMethods() {
    const {
      signInWithEmailAndPassword,
      createUserWithEmailAndPassword,
      sendPasswordResetEmail,
      signInAnonymously,
      signOut,
      onAuthStateChanged,
      signInWithCustomToken,
      updateProfile,
      getAuth
    } = await import("firebase/auth");

    return {
      signInWithEmailAndPassword,
      createUserWithEmailAndPassword,
      sendPasswordResetEmail,
      signInAnonymously,
      signOut,
      onAuthStateChanged,
      signInWithCustomToken,
      updateProfile,
      getAuth
    };
  }

  static async createUserWithEmail(email, password) {
    const { createUserWithEmailAndPassword, getAuth } = await this.loadAuthMethods();
    const auth = await getAuth(app);
    return await createUserWithEmailAndPassword(auth, email, password).catch(error => {
      throw new Error(error.message);
    });
  }

  static async setUserInfo(displayName) {
    const { updateProfile, getAuth } = await this.loadAuthMethods();
    const auth = await getAuth(app);
    await updateProfile(auth.currentUser, { displayName });
  }

  static async getCurrentUser() {
    const { onAuthStateChanged, getAuth } = await this.loadAuthMethods();
    const auth = await getAuth(app);
    return new Promise((resolve, reject) => {
      this.unsubscribe = onAuthStateChanged(
        auth,
        user => {
          resolve(user);
        },
        reject
      );
    });
  }

  static subscribeAuthChange(callback) {
    this.loadAuthMethods().then(async ({ onAuthStateChanged, getAuth }) => {
      const auth = await getAuth(app);
      this.unsubscribe = onAuthStateChanged(auth, callback);
    });
  }

  static unsubscribeAuthChange() {
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = null;
    }
  }

  static async logOut() {
    const { signOut, getAuth } = await this.loadAuthMethods();
    const auth = await getAuth(app);
    await signOut(auth).catch(error => {
      throw new Error(error.message);
    });
  }

  static async sendPasswordResetEmail(email) {
    const { sendPasswordResetEmail, getAuth } = await this.loadAuthMethods();
    const auth = await getAuth(app);
    await sendPasswordResetEmail(auth, email).catch(error => {
      throw new Error(error.message);
    });
  }

  static async loginWithEmail(email, password) {
    const { signInWithEmailAndPassword, getAuth } = await this.loadAuthMethods();
    const auth = await getAuth(app);
    return await signInWithEmailAndPassword(auth, email, password).catch(error => {
      throw new Error(error.message);
    });
  }

  static async loginAnonymous() {
    const { signInAnonymously, getAuth } = await this.loadAuthMethods();
    const auth = await getAuth(app);
    return await signInAnonymously(auth).catch(error => {
      throw new Error(error.message);
    });
  }

  static async loginCustom(customToken) {
    const { signInWithCustomToken, getAuth } = await this.loadAuthMethods();
    const auth = await getAuth(app);
    return await signInWithCustomToken(auth, customToken).catch(error => {
      throw new Error(error.message);
    });
  }
}
