import { makePersistable } from "mobx-persist-store";
import { makeAutoObservable } from "mobx";
import _set from "lodash.set";

const allWorkflowProps = {
  product: undefined,
  productName: "",
  flow: undefined,
  // date: moment().add(4, "days").format("YYYY-MM-DD"),
  date: undefined,
  numberPassengers: 1,
  fullName: "",
  email: "",
  phone: "",
  price: "",
  message: "",
  time: "",
  lng: "bg",
  skipPayment: true
};

export class WorkflowStore {
  constructor() {
    this.setDefaultData();
    makeAutoObservable(this);
    makePersistable(this, {
      name: "workflow",
      properties: Object.keys(allWorkflowProps),
      storage: typeof document !== "undefined" ? window.localStorage : null
    });
  }

  setDefaultData() {
    for (const key in allWorkflowProps) {
      if (allWorkflowProps.hasOwnProperty(key) && key !== "promoCodes") {
        this[key] = allWorkflowProps[key];
      }
    }
  }

  //computed prop
  // get nbPersons() {
  //   return this.adults + this.children;
  // }

  set(name, value) {
    this[name] = value;
  }

  setDeep(propertyPath, value) {
    _set(this, propertyPath, value);
  }

  async init(product, productName, flow = null, language) {
    this.setDefaultData();
    this.product = product;
    this.productName = productName;
    if (flow) {
      this.flow = flow;
    } else {
      this.flow = `${product}Flow`;
    }
    if (language) {
      this.lng = language;
    }
  }

  reset() {
    this.setDefaultData();
  }
}

const workflow = new WorkflowStore();
export default workflow;
